'use client';

import {
  IconAnalytics,
  IconApps,
  IconBenchmarking,
  IconFeedback,
  IconGroup,
  IconPuzzle,
  IconShapeChat,
  IconShapeHeadEngine,
  IconSpace,
} from '@unique/icons';
import { Assignments, useRoles } from '@unique/next-commons/authorization';
import { LayoutContext, UserProfileContext } from '@unique/shared-library';
import { usePathname } from 'next/navigation';
import { FC, useContext, useEffect } from 'react';
import { SpaceMenu } from './Space/SpaceMenu';

interface NavigationProps {
  chatUrl: string;
  knowledgeUploadUrl: string;
  adminUrl: string;
}

export const Navigation: FC<NavigationProps> = ({ chatUrl, knowledgeUploadUrl, adminUrl }) => {
  const { setTopLevelNavItems, setSecondLevelNavMenu, setSecondLevelFooter, setIsMenuExpanded } =
    useContext(LayoutContext);
  const { isLoading, assignments } = useContext(UserProfileContext);
  const {
    allowKnowledgeBaseAccess,
    allowFeedbackAccess,
    allowUserManagementAccess,
    allowSpaceAdminAccess,
    allowAppRepositoryAccess,
  } = useRoles();
  const pathname = usePathname();

  useEffect(() => {
    const isFeedbackActive = pathname?.includes('feedback');
    const isBenchmarkingActive = pathname?.includes('benchmarking');
    const isAnalyticsActive = pathname?.includes('analytics');
    const isChatActive = !isFeedbackActive && !isBenchmarkingActive && !isAnalyticsActive;

    if (isFeedbackActive || isBenchmarkingActive || isAnalyticsActive) {
      setIsMenuExpanded(false);
    }

    // If UserProfile is loading, we wait to display navigation items.
    setTopLevelNavItems([
      {
        href: chatUrl,
        name: 'Chat',
        icon: <IconShapeChat />,
        isVisible: true,
        isActive: isChatActive,
        prefetch: false,
      },
      {
        href: knowledgeUploadUrl,
        name: 'Knowledge base',
        icon: <IconShapeHeadEngine />,
        isVisible: allowKnowledgeBaseAccess,
        isActive: false,
        prefetch: false,
      },
      {
        href: chatUrl + '/benchmarking',
        name: 'Benchmarking',
        icon: <IconBenchmarking />,
        isVisible: allowFeedbackAccess,
        isActive: isBenchmarkingActive,
        prefetch: false,
      },
      {
        href: chatUrl + '/feedback',
        name: 'Feedback',
        icon: <IconFeedback />,
        isVisible: allowFeedbackAccess,
        isActive: isFeedbackActive,
        prefetch: false,
      },
      {
        href: chatUrl + '/analytics',
        name: 'Analytics',
        icon: <IconAnalytics />,
        isVisible: allowFeedbackAccess,
        isActive: isAnalyticsActive,
        prefetch: false,
      },
      {
        href: adminUrl + '/members',
        name: 'User Management',
        icon: <IconGroup />,
        isVisible: allowUserManagementAccess,
        isActive: false,
        prefetch: false,
      },
      {
        href: adminUrl + '/space',
        name: 'Space Management',
        icon: <IconSpace width="25px" height="25px" />,
        isVisible: allowSpaceAdminAccess || assignments.includes(Assignments.SPACE_MANAGER),
        isActive: false,
        prefetch: false,
      },
      {
        href: adminUrl + '/apps',
        name: 'Apps',
        icon: <IconApps />,
        isVisible: allowAppRepositoryAccess,
        isActive: false,
        prefetch: false,
      },
      {
        href: adminUrl + '/ai-modules',
        name: 'AI Module Templates',
        icon: <IconPuzzle />,
        isVisible: allowSpaceAdminAccess,
        isActive: false,
        prefetch: false,
      },
    ]);

    if (isChatActive) {
      setSecondLevelNavMenu(<SpaceMenu />);
    } else {
      setSecondLevelNavMenu(null);
      setSecondLevelFooter(null);
    }
  }, [
    chatUrl,
    adminUrl,
    allowFeedbackAccess,
    allowKnowledgeBaseAccess,
    allowUserManagementAccess,
    allowSpaceAdminAccess,
    allowAppRepositoryAccess,
    assignments,
    knowledgeUploadUrl,
    isLoading,
    setSecondLevelFooter,
    setSecondLevelNavMenu,
    setTopLevelNavItems,
    setIsMenuExpanded,
    pathname,
  ]);

  return <></>;
};
